import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Box,
  Button,
  Container,
  Stack,
  SimpleGrid,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import string from 'lodash/string';
import { BsDownload } from 'react-icons/bs';
import { ChevronRightIcon } from '@chakra-ui/icons';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';
import '../styles/insights.scss';
import CustomCarousel from '../components/carousel';
import AgronomyResearch from '../components/agronomy-research';

const Insights = (props) => {
  const { data } = props;
  const { truncate } = string;
  const [postsList, setPostsList] = useState(data.allWpCaseStudy.edges);
  const [currentTab, setCurrentTab] = useState(null);
  const tabSelector = (kind) => {
    setCurrentTab(kind);
    const filterResult = [];
    // eslint-disable-next-line array-callback-return
    data.allWpCaseStudy.edges.filter((node) => {
      const foundPost = node.node.caseCategories.nodes.find(
        (post) => post.slug === kind
      );
      if (foundPost) {
        filterResult.push(node);
      }
    });
    setPostsList(filterResult);
  };

  return (
    <Layout>
      <SEO
        title="Data & Insights About Sustainable Nutrient Solutions | Ostara"
        description="Explore the latest science and data to gain advantages with sustainable nutrient solutions, including higher yield, greater ROI, reduced runoff and more."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Data & Insights"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 0]}
                w={['100%', '100%', '100%', '48%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} spacing={6}>
                  <h1>Data & Insights</h1>
                  <p style={{ maxWidth: 400 }}>
                    Explore the latest science and data to gain an advantage
                    with sustainable nutrient solutions.
                  </p>
                </Stack>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box w="100%" p={4} className="insights-body-container">
          <Container
            className="insights-content-1--container"
            maxW={1400}
            marginTop={[10, 10, 10, 50]}
          >
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              spacing={0}
              className="insights-content-1--data"
            >
              <Box className="hero-img">
                <StaticImage
                  placeholder="transparent"
                  src="../images/Ostara_Technology.png"
                  alt="Ostara hero"
                />
              </Box>
              <Box className="insights-content-1--text">
                <h4>FEATURED</h4>
                <h3>{data.wpPost.title}</h3>

                {parse(
                  truncate(data.wpPost.excerpt, {
                    length: 150,
                    separator: /,?\.* +/,
                  })
                )}
                <CustomButton
                  maxW={230}
                  to={`/media/${data.wpPost.slug}`}
                  marginTop={10}
                >
                  READ MORE
                </CustomButton>
              </Box>
            </SimpleGrid>
          </Container>

          <Container
            className="insights-carousel-section"
            maxW={1400}
            marginTop={20}
          >
            <h4>Articles</h4>
            <CustomCarousel
              data={data.allWpArticle.edges}
              className="carousel-container"
            />
          </Container>
        </Box>

        <AgronomyResearch />

        <Box w="100%" p={4} className="insights-content-3">
          <Container
            className="insights-case-studies"
            maxW={1400}
            marginTop={50}
          >
            <h4 className="insights-heading">Case Studies</h4>
            <Box className="insights-content-3--textbox">
              {data.allWpCaseCategory.edges.map((category) => (
                <Text
                  key={category.node.slug}
                  onClick={() => tabSelector(category.node.slug)}
                  className={
                    currentTab === category.node.slug
                      ? 'insights-title active'
                      : 'insights-title'
                  }
                >
                  {category.node.name}
                </Text>
              ))}
            </Box>
            <Container maxW={1400} className="insights-content-3--data">
              <Box className="insights-articles">
                <Box>
                  {postsList && postsList.length > 0
                    ? postsList.map((item) => (
                        <Link
                          key={item.node.id}
                          to={`/case-studies/${item.node.slug}`}
                        >
                          <h5>{item.node.title}</h5>
                        </Link>
                      ))
                    : ''}
                </Box>
                <Link to="/case-studies">
                  <Button
                    className="custom-gray-btn"
                    fontSize="sm"
                    fontWeight={600}
                    color="#044606"
                    bg="#F1F1F1"
                    _hover={{
                      bg: '#044606',
                      color: 'white',
                    }}
                    rightIcon={<ChevronRightIcon w={8} h={8} />}
                  >
                    <p>View All</p>
                  </Button>
                </Link>
              </Box>

              <StaticImage
                placeholder="transparent"
                src="../images/insights-article-img.png"
                alt="Ostara img"
                className="case-study-banner"
              />
            </Container>
          </Container>
        </Box>
        <Box w="100%" p={4} className="insights-content-4">
          <Container className="insights-videos" maxW={1400} marginTop={50}>
            <h4 className="insights-heading">Videos</h4>

            <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
              {data.allWpVideo.edges.map((video) => (
                <Box key={video.node.id} className="insights-videos--container">
                  <Link to={`/videos/${video.node.slug}`}>
                    <Image
                      className="insights-videos--thumb"
                      placeholder="transparent"
                      src={video.node.featuredImage.node.sourceUrl}
                      alt={video.node.title}
                    />
                    <h5>{video.node.title}</h5>
                  </Link>
                  <div className="video-tags">
                    {video.node.videoCategories.nodes.map((tag) => (
                      <p key={tag.id}>{tag.name}</p>
                    ))}
                  </div>
                </Box>
              ))}
            </SimpleGrid>
          </Container>
          <Container className="insights-videos-footer" maxW={1400}>
            <Link to="/videos">
              <Button
                className="custom-gray-btn"
                fontSize="sm"
                fontWeight={600}
                color="#044606"
                bg="#F1F1F1"
                _hover={{
                  bg: '#044606',
                  color: 'white',
                }}
                rightIcon={<ChevronRightIcon w={8} h={8} />}
              >
                <h5>View All</h5>
              </Button>
            </Link>
          </Container>
        </Box>

        <Box w="100%" p={4} className="insights-content-5">
          <Container className="insights-downloads" maxW={1400} marginTop={150}>
            <h4 className="insights-heading">
              Downloads, Guides and Other Research
            </h4>
          </Container>
          <Container className="insights-downloads" maxW={1400} marginTop={150}>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={24}>
              {data.allWpDownload.edges.map((item) => (
                <div className="insights-downloads--item" key={item.node.id}>
                  <Image
                    src={`${item.node.featuredImage.node.sourceUrl}`}
                    alt="donwload img"
                    className="download-thumbnail"
                  />
                  <div className="download-title">
                    <a
                      href={item.node.download.file.mediaItemUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h5>{item.node.title}</h5>
                    </a>
                    <div className="download-tag-wrapper">
                      <div className="download-tag">
                        {item.node.downloadCategories.nodes.map((tag) => (
                          <p key={tag.id}>{tag.name}</p>
                        ))}
                      </div>
                      <a
                        href={item.node.download.file.mediaItemUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon boxSize={6} color="#044606" as={BsDownload} />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </SimpleGrid>
          </Container>
          <Container className="insights-download-footer" maxW={1400}>
            <Link to="/downloads">
              <Button
                className="custom-gray-btn"
                fontSize="sm"
                fontWeight={600}
                color="#044606"
                bg="#F1F1F1"
                _hover={{
                  bg: '#044606',
                  color: 'white',
                }}
                rightIcon={<ChevronRightIcon w={8} h={8} />}
              >
                <h5>View All</h5>
              </Button>
            </Link>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Insights;
export const pageQuery = graphql`
  query {
    wpPost(
      categories: { nodes: { elemMatch: { name: { eq: "Featured Post" } } } }
    ) {
      title
      excerpt
      slug
      heroImage {
        heroImage {
          sourceUrl
        }
      }
    }

    allWpArticle(limit: 3) {
      edges {
        node {
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }

    allWpCaseCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    allWpCaseStudy {
      edges {
        node {
          id
          slug
          title
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          caseCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }

    allWpDownload {
      edges {
        node {
          id
          title
          download {
            file {
              mediaItemUrl
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
          downloadCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }

    allWpVideo {
      edges {
        node {
          id
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          videoCategories {
            nodes {
              id
              name
              slug
            }
          }
          slug
        }
      }
    }
  }
`;
