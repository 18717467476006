import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import string from 'lodash/string';
import { ChevronRightIcon } from '@chakra-ui/icons';
import 'react-multi-carousel/lib/styles.css';
import '../styles/carousel.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 765 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 764, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const CustomButtonGroup = ({ next, previous }) => {
  return (
    <div className="custom-button-group">
      <Button
        className="custom-gray-btn"
        fontSize="sm"
        fontWeight={600}
        color="#044606"
        bg="#F1F1F1"
        _hover={{
          bg: '#044606',
          color: 'white',
        }}
        onClick={() => previous()}
      >
        ⟵
      </Button>
      <Button
        className="custom-gray-btn"
        fontSize="sm"
        fontWeight={600}
        color="#044606"
        bg="#F1F1F1"
        _hover={{
          bg: '#044606',
          color: 'white',
        }}
        onClick={() => next()}
      >
        ⟶
      </Button>
      <Link to="/articles">
        <Button
          className="custom-gray-btn"
          fontSize="sm"
          fontWeight={600}
          color="#044606"
          bg="#F1F1F1"
          _hover={{
            bg: '#044606',
            color: 'white',
          }}
          rightIcon={<ChevronRightIcon w={8} h={8} />}
        >
          <h5>View All</h5>
        </Button>
      </Link>
    </div>
  );
};

const CustomCarousel = ({ data }) => {
  const { truncate } = string;

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      arrows={false}
      customButtonGroup={<CustomButtonGroup />}
      infinite
      autoPlay
      autoPlaySpeed={4000}
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile']}
    >
      {data.map((item) => (
        <div key={item.node.id} className="carousel-item">
          <Image
            src={item.node.featuredImage.node.sourceUrl}
            alt="Ostara article thumbnail"
            className="carousel-item--thumbnail"
            width="100%"
            objectFit="cover"
          />
          <div className="carousel-item--content">
            <StaticImage
              className="carousel-item--icon"
              placeholder="transparent"
              src="../images/favicon.png"
              alt="Ostara logo"
            />
            <h5>{item.node.title}</h5>
            {parse(
              truncate(item.node.excerpt, {
                length: 120,
                separator: /,?\.* +/,
              })
            )}
            <Link to={`/articles/${item.node.slug}`}>Read more</Link>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
